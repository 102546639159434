<template>
  <div class="custom-table custom-table__wrapper">
    <table class="custom-table__table">
      <ReclamationInnerTableHead :head="head" />

      <tbody class="custom-table__body">
      <div
        v-for="(row, idx) of rows"
        :key="idx"
        class="custom-table__row"
      >
        <tr
          v-for="(childRow, i) of row.rows"
          :key="i"
          :class="i === 0 && 'relative'"
        >
          <td
            v-if="i === 0"
            :class="{
                    'table-border-bottom-bold': idx !== rows.length - 1
                  }"
            :rowspan="row.rows.length"
          >
            <div class="center">
              <span v-if="fullRowColumns[0].name === 'type'">{{ getDecision(row['type'])}}</span>
              <span v-else>{{ row[fullRowColumns[0].name] }}</span>
            </div>
          </td>

          <td
            v-if="i === 0"
            :class="{
                    'table-border-bottom-bold': idx !== rows.length - 1
                  }"
            :rowspan="row.rows.length"
          >
            <div class="center">
              {{ row[fullRowColumns[1].name] }}
            </div>
          </td>

          <td
            v-for="(col) of columns"
            :key="col.name"
            class='custom-table__td'
            :class="{
                    'table-border-bottom-bold': i === row.rows.length - 1 && idx !== rows.length - 1
                  }"
          >
            <router-link
              v-if="col.name === 'consignment_id'"
              class="link-reset v-link hover--main"
              :to="{ name: 'DetailedConsignment', params: { id_cons: childRow[col.name] } }"
            >
              Партия №{{ childRow.consignment_sort }} (№{{ childRow.order_account_number }} / №{{ childRow.position_sort }})
            </router-link>

            <span v-else-if="col.name === 'type'">{{ getDecision(childRow['type']) }}</span>
            <span v-else>{{ childRow[col.name] }}</span>
          </td>
        </tr>
      </div>
      </tbody>
    </table>
  </div>
</template>

<script>
import ReclamationInnerTableHead from './ReclamationInnerTableHead.vue'
import { getDecisionTable } from '@/utils/analytics/reclamationInner'

export default {
  components: {
    ReclamationInnerTableHead
  },
  props: {
    rows: {
      type: Array,
      required: true
    },
    columnsReport: {
      type: Array,
      required: true
    }
  },

  computed: {
    head () {
      return this.columnsReport
    },

    fullRowColumns () {
      return this.columnsReport.slice(0, 2)
    },

    columns () {
      return this.columnsReport.slice(2)
    }
  },
  methods: {
    getDecision (type) {
      return getDecisionTable(type)
    }
  }
}
</script>

<style lang="scss">
@import "@/shared/ui/CustomTable/styles";
</style>
